@base: #800080;

body,
div,
p,
section,
span,
li,
ul,
ol {
    margin: 0;
    padding: 0;
    margin-bottom: 0;

}

div>p {
    margin-bottom: 0;
}

#root {
    height: 100%;
    font-size: 14px;
    min-width: 1280px;
    overflow-x: auto;
}

.ant-layout {
    height: 100%;
}

aside.ant-layout-sider-children {
    height: 100;
}

.ant-layout-sider::-webkit-scrollbar {
    display: none;
}

header.ant-layout-header {
    padding: 0;
    padding-right: 20px;
    /* position: fixed; */
    top: 0;
    /* height: 80px; */
    width: 100%;
    opacity: 1;
}

.hover-pointer:hover {
    cursor: pointer;
}

main.ant-layout-content {
    height: calc(100% - 64);
    overflow-y: scroll;
    /* margin-top: 64px; */
}

// 文章管理样式
.bf-content {
    height: 100% !important;

    .DraftEditor-root {
        height: 100%;

        .DraftEditor-editorContainer {
            height: 100%;

            .notranslate {
                height: 100%;
            }
        }
    }
}

.map-container {
    display: none;
}

.container {
    padding: 20px;
}

.list-item {
    height: 80px;
}

.order-item {
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    align-items: center;

}

.top-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.top-item {
    // max-height: 300px;

    margin-bottom: 20px;

}

.ant-card-bordered {
    border-radius: 20px !important;
    height: 300px;
}

.ant-card-body {
    // background-color: pink;
    height: 243px;
    overflow: auto;
}

.top-item::-webkit-scrollbar {
    // display: none;
    width: 0 !important
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-container>div {
    width: 30%;

    max-height: 300px;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    margin-left: 15px;
    /* vertical-align: center; */
    align-items: center;
}

.ant-form-item {
    display: inline-flex !important;
}
.space-between {
    justify-content: space-between;
}

.space-arround {
    justify-content: space-around;
}

.inline-block {
    display: inline-block;
}

.edit-form-container>div {
    width: 40%;
}

button.button-selected {
    background-color: blueviolet;
    color: #fff;
}

button.button-theme {
    background-color: @base;
    color: #fff;
}

.chart-content {
    height: 800px;
    width: 800px;
    text-align: center;
}

.chart-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    vertical-align: center;
}

.chart-header {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 20px;
}

input.inline-input {
    width: 100px;
}

.normal-header,
.refresh-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    div {
        display: flex;
        align-items: center;
    }
}

button.active.ant-btn {
    background-color: #096dd9;
    color: #fff
}

.ant-upload-list {
    display: flex;
    flex-wrap:wrap;
}

.ant-upload-list>div {
    width: 200px;
    margin: 10px;
}

.add-goods-footer {
    text-align: center;

}

.add-goods-content {
    // text-align: center;
    margin-top: 50px;
    min-height: 300px;
}

.comment-container {
    display: flex;
    padding: 20px;
}

.comment-left {

    width: 30%;

    div {
        margin: 5px;
    }
}

.comment-right {
    width: 70%;
    padding: 50px;
    ;
}

.line {
    margin-top: 30px;
    border-bottom: solid 1px #ccc;
}

.ant-btn.add-goods-footer-btn:nth-of-type(1) {

    margin-right: 10px;
}

.custom-integral-item {
    margin: 10px;
    display: inline-block;
}

main.ant-layout-content::-webkit-scrollbar {
    width: 0 !important
}

/* main.ant-layout-content { overflow: -moz-scrollbars-none; }
main.ant-layout-content { -ms-overflow-style: none; } */
.ant-tabs {
    margin-right: 20px !important;
}

.ant-tabs-extra-content .ant-tabs-new-tab {
    margin-top: 5px !important;
    width: 30px !important;
    height: 30px !important;
    font-size: 20px !important;
    border: 1px solid #0a0a0a !important;
}