.file{
    background: #fff;
    width: 100px;
    height: 32px;
    position: relative;
    /* color: white; */
    color:rgba(0, 0, 0, 0.65);
    border-color: #d9d9d9;
    cursor: pointer;
    text-align: center;
    line-height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:4px;
    font-size:14px;
    overflow: hidden;
    margin-left: 20px;
    border: 1px solid #D9D9D9;
    line-height: 30px;
    transition: ease-in-out 0.4s;
}
.file:hover{
    background: #fff;
    width: 100px;
    height: 32px;
    position: relative;
    /* color: white; */
    color:rgba(64, 169, 255);
    border-color: #d9d9d9;
    cursor: pointer;
    text-align: center;
    line-height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:4px;
    font-size:14px;
    overflow: hidden;
    margin-left: 20px;
    border: 1px solid rgba(64, 169, 255);
    line-height: 30px;
    transition: ease-in-out 0.4s;
}
.file input{
    position: absolute;
    top: 0;
    bottom: 0;
    bottom: 0;  
    right: 0;
    opacity: 0;
}
