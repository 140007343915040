.avtivity {
  max-width: 400px;
}

.avtivity .demo {
  background: #fff !important;
  color: #000 !important;
  font-size: 16px;
  font-weight: 550;
}

.avtivity .border {
  border: none;
}
.divStyle{
  display: flex;
}
.inputStyle{
  width: 400px!important;
}
